ngb-toast {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1200;
  width: 100%;
  min-width: 300px;
}

.toast {
  background-color: var(--white);

  .toast-header {
    padding: 4px 15px;
    color: var(--white);
    background-color: var(--tan-color-two);
    font-family: 'Gotham Book' !important;
    font-size: 17px;
    text-transform: uppercase;
    border: none;
    .btn-close {
      display: none;
    }
  }
  .toast-body {
    background-color: var(--black);
    color: var(--white);
    border: none;
  }
  .close {
    position: absolute;
    top: 2px;
    right: 10px;
    opacity: 0.8;
    i {
      color: var(--white);
    }
  }
  .toast-content {
    width: 100%;
    min-width: 300px;
  }
}
